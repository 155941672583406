import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  HeaderContainer,
  HomeContainer,
  SideNavigationBarContainer,
  DividerContainer,
  FooterContainer,
  FormInputContainer,
  IconsContainer,
  LockContainer,
  MediaCarouselContainer,
  MediaUploadContainer,
  ModalContainer,
  SortableImageGridContainer,
  ImageGridContainer,
  PaginationContainer,
  RadioButtonsContainer,
  TableContainer,
  TabsContainer,
  ToggleSwitchContainer,
  TooltipContainer,
  FormDropdownContainer,
  AccordionContainer,
  ButtonContainer,
} from './container';

import Sidebar from './components/Sidebar/Sidebar';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="application-container">
        <div className="sidebar-container ">
          <Sidebar />
        </div>
        <div className="page-container">
          <Routes>
            <Route
              path="/"
              element={<HomeContainer />}
            />
            <Route
              exact
              path="/header"
              element={<HeaderContainer />}
            />
            <Route
              exact
              path="/sidenav"
              element={<SideNavigationBarContainer />}
            />
            <Route
              exact
              path="/accordion"
              element={<AccordionContainer />}
            />
            <Route
              exact
              path="/button"
              element={<ButtonContainer />}
            />
            <Route
              exact
              path="/divider"
              element={<DividerContainer />}
            />
            <Route
              exact
              path="/footer"
              element={<FooterContainer />}
            />
            <Route
              exact
              path="/formDropdown"
              element={<FormDropdownContainer />}
            />
            <Route
              exact
              path="/formInput"
              element={<FormInputContainer />}
            />
            <Route
              exact
              path="/icons"
              element={<IconsContainer />}
            />
            <Route
              exact
              path="/lock"
              element={<LockContainer />}
            />
            <Route
              exact
              path="/mediacarousel"
              element={<MediaCarouselContainer />}
            />
            <Route
              exact
              path="/mediaupload"
              element={<MediaUploadContainer />}
            />
            <Route
              exact
              path="/modal"
              element={<ModalContainer />}
            />
            <Route
              exact
              path="/imagegrid"
              element={<ImageGridContainer />}
            />
            <Route
              exact
              path="/pagination"
              element={<PaginationContainer />}
            />
            <Route
              exact
              path="/radio"
              element={<RadioButtonsContainer />}
            />
            <Route
              exact
              path="/sortableimagegrid"
              element={<SortableImageGridContainer />}
            />
            <Route
              exact
              path="/tabs"
              element={<TabsContainer />}
            />
            <Route
              exact
              path="/table"
              element={<TableContainer />}
            />
            <Route
              exact
              path="/toggleswitch"
              element={<ToggleSwitchContainer />}
            />
            <Route
              exact
              path="/tooltip"
              element={<TooltipContainer />}
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;

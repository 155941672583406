import React from 'react';
import FormInput from '../FormInput/FormInput';
import { RadioButton } from '../index';
import styles from './RadioButtonInputGroup.module.scss';

const RadioButtonInputGroup = ({ id, buttons, onChange, value, horizontal, disabled, className }) => {
  return (
    <div
      id={id}
      className={`${styles.radioButtonInputGroupContainer} ${className ?? ''} radio-button-input-group-container`}
      style={{ flexDirection: `${horizontal ? 'row' : 'column'}` }}
    >
      {buttons.map(({ ...radioProps }, index) => (
        <div
          className={`${styles.radioButtonInput} radio-button-input`}
          key={`radio-button-group-${id ? id + '-' : ''}${index}`}
        >
          <RadioButton
            value={radioProps.radioButtonValue}
            checked={value === radioProps.radioButtonValue ? true : null}
            onClick={() => onChange(radioProps.radioButtonValue)}
            radiofirst
            disabled={radioProps.disabled || disabled ? true : null}
          ></RadioButton>
          <div className={`${styles.radioButtonInputFormInput} radio-button-input-forminput`}>
            {radioProps?.radioLabel && <div className={styles.radioButtonInputLabel}> {radioProps.radioLabel}</div>}
            <FormInput
              disabled={value !== radioProps.radioButtonValue || disabled ? true : null}
              {...radioProps.inputProps}
            ></FormInput>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RadioButtonInputGroup;

import React from 'react';
import { NavLink } from 'react-router-dom';

import './Sidebar.css';

const Sidebar = (props) => {
  return (
    <nav className="sidebar">
      <div className="components-heading">
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Components
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/header"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Header
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/sidenav"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Side Navigation Bar
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/accordion"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Accordion
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/button"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Button
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/divider"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Divider
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/footer"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Footer
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/formDropdown"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Form Dropdown
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/formInput"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Form Input
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/icons"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Icons
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/lock"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Lock
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/mediacarousel"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Media Carousel
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/mediaupload"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Media Upload
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/modal"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Modal
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/imagegrid"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Image Grid
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/sortableimagegrid"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Sortable Image Grid
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/pagination"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Pagination
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/radio"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Radio Buttons
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/table"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Table
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/tabs"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Tabs
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/toggleswitch"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Toggle Switch
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/tooltip"
          className={({ isActive }) => (isActive ? 'active-link' : '')}
        >
          Tooltip
        </NavLink>
      </div>
    </nav>
  );
};

export default Sidebar;

import React from 'react';
import styles from './IconsContainer.module.scss';
import {
  Adjust,
  Camera,
  Cancel,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Edit,
  Eye,
  FloppyDisk,
  Lock,
  LockOpen,
  LockUnselected,
  Question,
  Search,
  SortDown,
  SortUp,
  TrashCan,
  Upload,
  Video,
  VideoBigTag,
  VideoTag,
} from '../../icons';

export default function IconsContainer() {
  return (
    <div className="page-component-spacing">
      <h4>Icons</h4>
      <div className={styles.icons}>
        <div className={styles.icon}>
          <Adjust />
          Adjust
        </div>
        <div className={styles.icon}>
          <Camera />
          Camera
        </div>
        <div className={styles.icon}>
          <Cancel />
          Cancel
        </div>
        <div className={styles.icon}>
          <CheckCircle />
          CheckCircle
        </div>
        <div className={styles.icon}>
          <ChevronDown color="#00528A" />
          ChevronDown
        </div>
        <div className={styles.icon}>
          <ChevronLeft />
          ChevronLeft
        </div>
        <div className={styles.icon}>
          <ChevronRight />
          ChevronRight
        </div>
        <div className={styles.icon}>
          <Edit />
          Edit
        </div>
        <div className={styles.icon}>
          <Eye />
          Eye
        </div>
        <div className={styles.icon}>
          <FloppyDisk />
          FloppyDisk
        </div>
        <div className={styles.icon}>
          <Lock />
          Lock
        </div>
        <div className={styles.icon}>
          <LockOpen />
          LockOpen
        </div>
        <div className={styles.icon}>
          <LockUnselected />
          LockUnselected
        </div>
        <div className={styles.icon}>
          <Question />
          Question
        </div>
        <div className={styles.icon}>
          <Search />
          Search
        </div>
        <div className={styles.icon}>
          <SortUp />
          SortUp
        </div>
        <div className={styles.icon}>
          <SortDown />
          SortDown
        </div>
        <div className={styles.icon}>
          <TrashCan />
          TrashCan
        </div>
        <div className={styles.icon}>
          <Upload />
          Upload
        </div>
        <div className={styles.icon}>
          <Video />
          Video
        </div>
        <div className={styles.icon}>
          <VideoBigTag />
          VideoBigTag
        </div>
        <div className={styles.icon}>
          <VideoTag />
          VideoTag
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import styles from './FormInput.module.scss';
import Lock from '../Lock';

const countNonNumeric = (inputValue) => {
  if (!inputValue) return 0;
  const inputLength = inputValue.length;
  const numericOnly = inputValue.replace(/\D/g, '');
  return inputLength - numericOnly.length;
};

const isDeletingNonNumeric = (input, startPos) => {
  if (startPos === 0) return false;

  const deletedChar = input.slice(startPos - 1, startPos);
  if (!/^\d+$/.test(deletedChar)) {
    return true;
  }
  return false;
};

export default function FormInput({
  id,
  type,
  label,
  name,
  value,
  placeholder,
  error,
  disabled,
  variant,
  autofocus,
  onInput,
  prefix,
  postfix,
  required,
  readOnly,
  lockStatus,
  onLockClick,
  numericalMask,
  maxlength,
  className,
  style,
  ...rest
}) {
  const [changeCounter, setChangeCounter] = useState(0);
  const [prevCaretPosition, setPreviousCaretPosition] = useState(0);
  const [prevNonNumCount, setPrevNonNumCount] = useState(countNonNumeric(value));

  const onChange = (e) => {
    if (
      type === 'text' &&
      numericalMask &&
      document.getElementById(id) &&
      document.getElementById(id)?.shadowRoot?.querySelector('input')
    ) {
      const currentSelectionStart = document.getElementById(id)?.shadowRoot?.querySelector('input')?.selectionStart;
      const newNonNumCount = countNonNumeric(document.getElementById(id)?.shadowRoot?.querySelector('input')?.value);
      setPreviousCaretPosition(currentSelectionStart); // Keep track of previous caret position

      setPrevNonNumCount(newNonNumCount);
    }
    onInput(e);
    setChangeCounter((prev) => prev + 1);
  };

  useEffect(() => {
    // Need to manually update caret position in case input value is masked
    if (type === 'text' && numericalMask && document.getElementById(id)?.shadowRoot?.querySelector('input')) {
      try {
        const inputField = document.getElementById(id)?.shadowRoot?.querySelector('input');
        let currentCaretPosition = prevCaretPosition;
        const currentNonNumericCount = countNonNumeric(inputField?.value);
        currentCaretPosition += currentNonNumericCount - prevNonNumCount;
        currentCaretPosition = currentCaretPosition >= 0 ? currentCaretPosition : 0;
        inputField?.setSelectionRange(currentCaretPosition, currentCaretPosition);
      } catch {
        console.debug('Error in input field: ', id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCounter]);

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById(id)?.shadowRoot && maxlength) {
        const inputField = document?.getElementById(id)?.shadowRoot?.querySelector('input');

        if (inputField && maxlength) {
          inputField.setAttribute('maxlength', maxlength);
        }

        if (inputField && type === 'text' && numericalMask) {
          inputField.addEventListener('keydown', (e) => {
            if (e.isComposing || e.key !== 'Backspace') {
              return;
            }
            const currentCaretPosition = inputField.selectionStart;

            // If pressing backspace on a masked-injected character, move the caret past the mask-injected character
            if (isDeletingNonNumeric(e.target.value, currentCaretPosition)) {
              inputField.setSelectionRange(currentCaretPosition - 1, currentCaretPosition - 1);
            }
          });
        }
      }
    }, 50);
  }, [id, maxlength, numericalMask, type]);

  return (
    <div className={`${styles.inputContainer} ${style?.inputContainer || ''}`}>
      {label && (
        <div className={`${styles.label} ${style?.label || ''}`}>
          {label} {required && <span className={styles.required}>*</span>}
        </div>
      )}
      <div className={`${styles.value} ${style?.value || ''}`}>
        {!readOnly && (
          <nsemble-input
            id={id}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder ?? ''}
            error={error}
            disabled={disabled}
            variant={variant}
            autofocus={autofocus}
            onInput={onChange}
            prefix={prefix}
            postfix={postfix}
            class={className}
            changes={changeCounter}
            {...rest}
          />
        )}
        {readOnly && <div>{value}</div>}
        <Lock
          lockStatus={lockStatus}
          onClick={onLockClick}
        />
      </div>
    </div>
  );
}

import React from 'react';

export default function EditIcon({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 20.2451L21 20.2451"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M17.5887 2.82843C18.3698 2.04738 19.6361 2.04738 20.4171 2.82843C21.1982 3.60948 21.1982 4.87581 20.4171 5.65685L7.94711 18.1269L3.72162 19.5239L5.11868 15.2985L17.5887 2.82843Z"
        stroke={color}
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
}

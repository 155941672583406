import React from 'react';

export default function AdjustIcon({ color = '#00528A', ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle
        cx="3"
        cy="3"
        r="2"
        transform="matrix(-1 0 0 1 12 3)"
        stroke={color}
        stroke-width="2"
      />
      <circle
        cx="3"
        cy="3"
        r="2"
        transform="matrix(-1 0 0 1 18 9)"
        stroke={color}
        stroke-width="2"
      />
      <circle
        cx="3"
        cy="3"
        r="2"
        transform="matrix(-1 0 0 1 12 15)"
        stroke={color}
        stroke-width="2"
      />
      <path
        d="M17 12H20.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M13 12H3.5"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M7 18L3.5 18"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M7 6L3.5 6"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M11 18L20.5 18"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M11 6L20.5 6"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}

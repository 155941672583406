import React, { useState } from 'react';
import { RadioButton, RadioButtonGroup, RadioButtonInputGroup } from '../../reusableComponents';
import PropsDescription from '../../components/PropsDescription/PropsDescription';

export default function RadioButtonsContainer() {
  const [singleButtonChecked, setSingleButtonChecked] = useState(true);
  const [selectedRadioInput, setSelectedRadioInput] = useState('make');
  const radioButtonProps = [
    {
      name: 'label',
      type: 'string',
      notes: '(optional) provides the text label of the radio button',
    },
    {
      name: 'value',
      type: 'string',
      notes: '(optional) provides a value for the radio button',
    },
    {
      name: 'disabled',
      type: 'boolean or null',
      notes: 'if present, disables the radio button (works for both on and off)',
    },
    {
      name: 'checked',
      type: 'boolean or null',
      notes: 'if present, sets the radio button to the "on"/selected state',
    },
    {
      name: 'radiofirst',
      type: 'boolean or null',
      notes: 'if present, sets the radio button before the label in mobile',
    },
  ];

  const radioButtonGroupProps = [
    {
      name: 'name',
      type: 'string',
      notes: '(optional) provides the name attribute of the radio button input elements',
    },
    {
      name: 'value',
      type: 'string',
      notes: '(optional) if present, selects the radio button with the corresponding value',
    },
    {
      name: 'disabled',
      type: 'boolean or null',
      notes: '(optional) if present, disabled all radio buttons in the group',
    },
    {
      name: 'buttons',
      type: 'array',
      notes:
        '(required) JSON string containing the label, value, and disabled state of the radio buttons in the radio group',
    },
    {
      name: 'horizontal',
      type: 'boolean or null',
      notes: '(optional) if present, forces radio buttons to be horizontal iso vertical',
    },
    {
      name: 'onClick',
      type: 'callback function',
      notes: 'handle click events where event.target.value is the value of the radio button selected',
    },
  ];

  const radioButtonInputGroupProps = [
    {
      name: 'value',
      type: 'string',
      notes: '(optional) if present, selects the radio button with the corresponding value',
    },
    {
      name: 'disabled',
      type: 'boolean',
      notes: '(optional) if present, disabled all radio buttons in the group',
    },
    {
      name: 'buttons',
      type: 'array',
      notes:
        '(required) array of objects containing the radio button label (radioLabel), radio button value (radioButtonValue), inputProps (inputProps - refer to Form Input Component), and disabled state',
    },
    {
      name: 'horizontal',
      type: 'boolean',
      notes: '(optional) if present, forces radio buttons to be horizontal iso vertical',
    },
    {
      name: 'className',
      type: 'string',
      notes: 'Apply className to radio group',
    },
    {
      name: 'onChange',
      type: 'callback function',
      notes: 'handle radio button click events where event is the value of the radio button selected',
    },
  ];

  const onRadioButtonInputChange = (selected) => {
    setSelectedRadioInput(selected);
  };

  return (
    <div className="page-component-spacing">
      <h4>Radio Button</h4>
      <div>
        <RadioButton
          checked={singleButtonChecked || null}
          onClick={() => {
            setSingleButtonChecked((prev) => !prev);
          }}
        ></RadioButton>
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={radioButtonProps} />
      </div>
      <hr></hr>
      <br></br>
      <h4>Radio Button Group</h4>
      <h5>Vertical</h5>
      <div>
        <RadioButtonGroup
          id="test-radio-group"
          value="Aardvark"
          name="group_test"
          buttons='[{"label":"Aardvark"},{"label":"Badger"},{"label":"Catbird"}]'
        ></RadioButtonGroup>
      </div>
      <h5>Horizontal</h5>
      <div>
        <RadioButtonGroup
          id="test-radio-group"
          value="Aardvark"
          name="group_test"
          buttons='[{"label":"Aardvark"},{"label":"Badger"},{"label":"Catbird"}]'
          horizontal
        ></RadioButtonGroup>
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={radioButtonGroupProps} />
      </div>
      <hr></hr>
      <br></br>
      <h4>Radio Button Input Group</h4>
      <div>
        <RadioButtonInputGroup
          buttons={[
            { radioLabel: 'Make', inputProps: { value: 'Toyota' }, radioButtonValue: 'make' },
            { radioLabel: 'Model', inputProps: { value: 'RAV4' }, radioButtonValue: 'model' },
          ]}
          onChange={onRadioButtonInputChange}
          value={selectedRadioInput}
          horizontal={true}
        ></RadioButtonInputGroup>
      </div>
      <div className="page-component-spacing">
        <PropsDescription componentProps={radioButtonInputGroupProps} />
      </div>
    </div>
  );
}
